import { ClubGrid, ClubSection } from '@/scenes/Home/Club/styles';
import List from '@/components/List';
import { useEffect, useRef, useState } from 'react';
import { gsap, Power4 } from 'gsap';
import { SplitText } from '@/utils/gsap/SplitText';
import uniq from 'lodash.uniq';

interface Props {
  title: string;
  description: string;
  list: { id: number; item: string }[];
}

const Club = ({ title, description, list }: Props) => {
  const clubFeatures: string[] = list.map((item) => item.item);

  const animatedItems = useRef([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const descriptionTags = description.split('**');

  useEffect(() => {
    if (!loaded) return;

    const redWordsGap = descriptionTags[0].split(' ').length;
    const redWordsCount = descriptionTags[1].split(' ').length;

    const descriptionWordsSplit = new SplitText('.subtitle p', {
      type: 'words',
      wordsClass: 'word++',
    });

    const red = descriptionWordsSplit.words.filter(
      (i, index) =>
        index >= redWordsGap - 1 && index <= redWordsGap + redWordsCount - 2,
    );

    gsap.set(red, {
      color: '#E85143',
    });

    const descriptionLinesSplit = new SplitText('.subtitle p', {
      type: 'lines',
      linesClass: 'animatedLineChild',
    });

    let descriptionTl = gsap.timeline({
      scrollTrigger: {
        // @ts-ignore
        trigger: '.subtitle p',
        start: 'top 92%',
      },
    });
    descriptionTl.fromTo(
      descriptionLinesSplit.lines,
      {
        transition: 'none',
        transformOrigin: '0% 0%',
        scaleY: 1,
        yPercent: 100,
      },
      {
        duration: 0.75,
        transformOrigin: '0% 0%',
        delay: 0.2,
        scaleY: 1,
        opacity: 1,
        y: 0,
        yPercent: 0,
        stagger: 0.075,
        ease: Power4.easeInOut,
        onComplete: () => {
          // @ts-ignore
          // childSplit.revert();
        },
      },
    );

    gsap.utils
      .toArray([...uniq(animatedItems.current.filter((i) => i))])
      .forEach((element) => {
        const childSplit = new SplitText(element, {
          type: 'lines',
          linesClass: 'animatedLineChild',
        });

        let tl = gsap.timeline({
          scrollTrigger: {
            // @ts-ignore
            trigger: element,
            start: 'top 92%',
          },
        });
        tl.fromTo(
          childSplit.lines,
          {
            transition: 'none',
            transformOrigin: '0% 0%',
            scaleY: 1,
            yPercent: 100,
          },
          {
            duration: 0.75,
            transformOrigin: '0% 0%',
            delay: 0.2,
            scaleY: 1,
            opacity: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.075,
            ease: Power4.easeInOut,
            onComplete: () => {
              // @ts-ignore
              childSplit.revert();
            },
          },
        );
      });
  }, [loaded]);

  return (
    <ClubSection>
      <div className="container">
        <ClubGrid>
          <h2 className="title" ref={(r) => animatedItems.current.push(r)}>
            {title}
          </h2>
          <div className="subtitle">
            <p>{descriptionTags.join(' ')}</p>
          </div>
          <List
            items={clubFeatures}
            className="list"
            ref={(r) => animatedItems.current.push(r)}
          />
        </ClubGrid>
      </div>
    </ClubSection>
  );
};

export default Club;
