import { StyledList } from '@/components/List/styles';
import ArrowRight from '@/assets/arrow-right.svg';
import { forwardRef } from 'react';

interface ListProps {
  items: string[];
  down?: boolean;
  wide?: boolean;
  className?: string;
}

const List = forwardRef(({ items, className, down, wide }: ListProps, ref) => {
  if (!items.length) return null;

  return (
    <StyledList className={className} down={down} wide={wide} ref={ref}>
      {items.map((item) => (
        <li key={item}>
          <ArrowRight />
          <span>{item}</span>
        </li>
      ))}
    </StyledList>
  );
});

List.displayName = 'List';

export default List;
