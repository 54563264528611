import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface StyledListProps {
  down: boolean;
  wide: boolean;
}

const StyledList = styled.ul<StyledListProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-content: flex-start;
    padding: ${({ theme }) => theme.spacings.x2}
      ${({ theme }) => theme.spacings.x5} ${({ theme }) => theme.spacings.x2} 0;

    ${breakpoints.MD} {
      padding-right: 0;

      &:first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.divider};
      }
    }

    svg {
      min-width: 13px;
    }

    span {
      padding-left: ${({ theme }) => theme.spacings.x4};

      ${breakpoints.MD} {
        line-height: 22px;
      }

      ${breakpoints.XXLG} {
        font-size: 18px;
        line-height: 24px;
      }
    }

    & + li {
      border-top: 1px solid ${({ theme }) => theme.colors.divider};
    }
  }

  ${({ down }) =>
    down &&
    css`
      li {
        svg {
          transform: rotate(90deg);
        }
      }
    `}

  ${({ wide }) =>
    wide &&
    css`
      li {
        padding-right: 10px;
      }
    `}
`;

export { StyledList };
