import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const ClubSection = styled.section`
  padding: ${({ theme }) => parseInt(theme.spacings.x10) + 6}px 0
    ${({ theme }) => parseInt(theme.spacings.x8) + 1}px;

  ${breakpoints.MD} {
    padding: ${({ theme }) => parseInt(theme.spacings.x20) + 9}px 0
      ${({ theme }) => parseInt(theme.spacings.x18)}px;
  }

  ${breakpoints.LG} {
    padding: ${({ theme }) => parseInt(theme.spacings.x20) + 2}px 0
      ${({ theme }) => parseInt(theme.spacings.x18)}px;
  }

  .title {
    margin-bottom: ${({ theme }) => theme.spacings.x4};

    ${breakpoints.MD} {
      margin: 0;
    }

    ${breakpoints.MDL} {
      margin-bottom: -7px;
    }

    ${breakpoints.LG} {
      position: relative;
      top: 10px;
      padding-right: 20%;
    }

    ${breakpoints.XXLG} {
      margin-left: 4.5%;
    }
  }

  .subtitle {
    & {
      @media (max-width: 767px) {
        margin-bottom: ${({ theme }) => theme.spacings.x7} !important;
      }
    }

    ${breakpoints.MD} {
      margin-bottom: ${({ theme }) => theme.spacings.x2};
      padding-right: 20%;
    }

    ${breakpoints.MDL} {
      padding-right: 40%;
    }

    ${breakpoints.LG} {
      margin-top: -9px;
      padding-right: 0;
    }

    ${breakpoints.XXLG} {
      margin-top: -21px;
      font-size: 32px;
      line-height: 40px;
      padding-right: 0;
    }
  }
`;

const ClubGrid = styled.div`
  ${breakpoints.MD} {
    display: grid;
    column-gap: ${({ theme }) => theme.spacings.x4};
    row-gap: ${({ theme }) => theme.spacings.x8};
    grid-template-columns: repeat(12, 12fr);
    grid-template-areas:
      'title .'
      'subtitle list';

    .title {
      grid-area: title;
      grid-column: span 10;
    }

    .subtitle {
      grid-area: subtitle;
      grid-column: span 8;
      align-self: end;
    }

    .list {
      grid-area: list;
      grid-column: span 4;
    }
  }

  ${breakpoints.MDL} {
    row-gap: 0;

    .title {
      grid-column: span 8;
    }
  }

  ${breakpoints.LG} {
    row-gap: ${({ theme }) => theme.spacings.x10};
    grid-template-areas:
      'title . list'
      '. subtitle list';

    .title {
      grid-column: span 9;
    }

    .list {
      grid-column: span 3;
    }

    .subtitle {
      grid-column-start: 6;
      grid-column-end: 9;
    }
  }
`;

export { ClubSection, ClubGrid };
